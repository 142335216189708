import { createSelector } from '@ngrx/store';
import { AppPermissionsEnum, IAppState, checkUserAppPermissions } from 'common';
import { CustomerDataPlatformState } from '../interfaces';
import { Meters } from './meters.interface';

export interface AppState {
    appState: IAppState,
    customerDataPlatformState: CustomerDataPlatformState,
}

export const meterState = (state: AppState) => state.customerDataPlatformState?.metersData?.data;
export const userState = (state: AppState) => state.appState.user;

export const getReusableProperty = createSelector(
    meterState,
    (state: Meters) => {
        return state?.reusableProperty;
    }
);

export const getOlyticsSites = createSelector(
    meterState,
    (state: Meters) => {
        return state?.olyticsSiteList;
    }
);

export const getReportData = createSelector(
    meterState,
    (state: Meters) => {
        return state?.reportData;
    }
);

export const getOlyticSiteMetersList = createSelector(
    meterState,
    (state: Meters) => {
        return state?.olyticsSiteMetersMap;
    }
);

export const getArchivedMeters = createSelector(
    meterState,
    (state: Meters) => {
        return state?.archivedMeters;
    }
);

export const getOlyticsSiteData = createSelector(
    meterState,
    (state: Meters) => {
        return state?.olyticsSiteData;
    }
);

export const getMeterData = createSelector(
    meterState,
    (state: Meters) => {
        return state?.createUpdateMeterData;
    }
);

export const getMeterMessagesTable = createSelector(
    meterState,
    (state: Meters) => {
        return state?.messageContentData;
    }
);

export const getSelectedMeterContent = createSelector(
    meterState,
    (state: Meters) => {
        return state?.selectedMessageContent;
    }
);

export const getMeterModificationHistory = createSelector(
    meterState,
    (state: Meters) => {
        return state?.meterModificationHistory;
    }
);

export const getGenerateTestLinkData = createSelector(
    meterState,
    (state: Meters) => {
        return state?.generateTestLinkData;
    }
);

export const showUnsavedChangesModal = createSelector(
    meterState,
    (state: Meters) => {
        return state?.showUnsavedModal;
    }
);

export const getMeterPageLoadingState = createSelector(
    meterState,
    (state: Meters) => {
        return state?.meterPageLoading;
    }
);

export const getIpAddressExclusionList = createSelector(
    meterState,
    (state: Meters) => {
        return state?.meterIpAddressExlusionList;
    }
);

export const checkUserMeterPermissions = createSelector(
    checkUserAppPermissions(AppPermissionsEnum.CDP_create_meter_sites),
    checkUserAppPermissions(AppPermissionsEnum.CDP_edit_meter_sites),
    checkUserAppPermissions(AppPermissionsEnum.CDP_create_meters),
    checkUserAppPermissions(AppPermissionsEnum.CDP_edit_meters),
    userState,
    (createMeterSites, editMeterSites, createMeter, editMeter, userState) => {
        return {
            createMeterSites,
            editMeterSites,
            createMeter,
            editMeter,
            userState
        };
    }
);

export const saveMeterMessage = createSelector(
    meterState, state => state?.saveMeterMessage
);

export const navBarActions = createSelector(
    meterState, state => state?.navBarActions
);
