import { Component } from '@angular/core';
import { ModalBase } from 'common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    templateUrl: './empty-dialog.modal.html',
})

export class EmptyDialogModal extends ModalBase {
    public data: { messageContent?: string };
    public messageContent;

    constructor(private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        if (this.data && this.data?.messageContent) {
            this.messageContent = this.sanitizer.bypassSecurityTrustHtml(this.data?.messageContent);
        }
    }
}