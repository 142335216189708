import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { OlyticsSitesResults, MetersSearch, OlyticsSiteMeters, ReportData, OlyticSiteData, AddTesterResponse, IpAddressExlusionList, MeterMessagesTableResponse, MeterDataResponse, CreateUpdateMeterDataResponse, NavBarAction, SaveAudiencePayload, AddedMeterMessageItem, MeterLink, SaveMeterMessageResponse, SelectedMeterContent, UserMeterPermissions, SearchData, MessageContentSave } from '@cdp/state/meters/meters.interface';

export enum CustomerDataPlatformMetersInnerActionTypes {
    // OLYTICS SITES ACTIONS
    GetOlyticsSitesSuccess = '[CdpMeters] Get Olytics Sites Success',
    GetOlyticsSitesError = '[CdpMeters] Get Olytics Sites Error',
    GetOlyticSiteMetersListSuccess = '[CdpMeters] Get Olytic Site Meters List Success',
    GetOlyticSiteMetersListError = '[CdpMeters] Get Olytic Site Meters List Error',
    DeleteDomainSuccess = '[CdpMeters] Delete Domain Success',
    DeleteDomainError = '[CdpMeters] Delete Domain Error',
    GetOlyticsSiteReportSuccess = '[CdpMeters] Get Olytics Site Report Success',
    GetOlyticsSiteReportError = '[CdpMeters] Get Olytics Site Report Error',
    GetOlyticsSiteDataSuccess = '[CdpMeters] Get Olytics Site Data Success',
    GetOlyticsSiteDataError = '[CdpMeters] Get Olytics Site Data Error',
    CreateUpdateOlyticsSiteSuccess = '[CdpMeters] Create Update Olytics Site Success',
    CreateUpdateOlyticsSiteError = '[CdpMeters] Create Update Olytics Site Error',

    // METERS ACTIONS
    GetArchivedMetersSuccess = '[CdpMeters] Get Archived Meters Success',
    GetArchivedMetersError = '[CdpMeters] Get Archived Meters Error',
    SetMeterPrioritySuccess = '[CdpMeters] Set Meter Priority Success',
    SetMeterPriorityError = '[CdpMeters] Set Meter Priority Error',
    ArchiveMeterSuccess = '[CdpMeters] Archive Meter Success',
    ArchiveMeterError = '[CdpMeters] Archive Meter Error',
    RestoreArchivedMeterSuccess = '[CdpMeters] Restore Archived Meter Success',
    RestoreArchivedMeterError = '[CdpMeters] Restore Archived Meter Error',
    DeleteMeterSuccess = '[CdpMeters] Delete Meter Success',
    DeleteMeterError = '[CdpMeters] Delete Meter Error',
    CloneMeterSuccess = '[CdpMeters] Clone Meter Success',
    CloneMeterError = '[CdpMeters] Clone Meter Error',
    GetMeterReportSuccess = '[CdpMeters] Get Meter Report Success',
    GetMeterReportError = '[CdpMeters] Get Meter Report Error',
    GetMeterDataSuccess = '[CdpMeters] Get Meter Data Success',
    GetMeterDataError = '[CdpMeters] Get Meter Data Error',
    CreateUpdateMeterDataSuccess = '[CdpMeters] Create Update Meter Data Success',
    CreateUpdateMeterDataError = '[CdpMeters] Create Update Meter Data Error',
    GetMeterMessageContentDataSuccess = '[CdpMeters] Get Meter Message Content Data Success',
    GetMeterMessageContentDataError = '[CdpMeters] Get Meter Message Content Data Error',
    GetMeterSingleMessageContentSuccess = '[CdpMeters] Get Meter Single Message Content Success',
    GetMeterSingleMessageContentError = '[CdpMeters] Get Meter Single Message Content Error',
    DeleteMeterMessageSuccess = '[CdpMeters] Delete Meter Message Success',
    DeleteMeterMessageError = '[CdpMeters] Delete Meter Message Error',
    CloneMeterMessageSuccess = '[CdpMeters] Clone Meter Message Success',
    CloneMeterMessageError = '[CdpMeters] Clone Meter Message Error',
    SaveMeterMessageContentSuccess = '[CdpMeters] Save Meter Message Content Success',
    SaveMeterMessageContentError = '[CdpMeters] Save Meter Message Content Error',
    DeleteOrResetTesterSuccess = '[CdpMeters] Delete Or Reset Tester Success',
    DeleteOrResetTesterError = '[CdpMeters] Delete Or Reset Tester Error',
    AddTesterSuccess = '[CdpMeters] Add Tester Success',
    AddTesterError = '[CdpMeters] Add Tester Error',
    GetMeterHistorySuccess = '[CdpMeters] Get Meter History Success',
    GetMeterHistoryError = '[CdpMeters] Get Meter History Error',
    GenerateMeterLinkSuccess = '[CdpMeters] Genrate Meter Link Success',
    GenerateMeterLinkError = '[CdpMeters] Genrate Meter Link Error',
    SetMeterInTestModeSuccess = '[CdpMeters] Set Meter In Test Mode Success',
    SetMeterInTestModeError = '[CdpMeters] Set Meter In Test Mode Error',
    SetMeterInActivateModeSuccess = '[CdpMeters] Set Meter In Activate Mode Success',
    SetMeterInActivateModeError = '[CdpMeters] Set Meter In Activate Mode Error',
    GetIpAddressExclusionListSuccess = '[CdpMeters] Get Ip Address Exclusion List Success',
    GetIpAddressExclusionListError = '[CdpMeters] Get Ip Address Exclusion List Error',
    AddIpAddressToExclusionListSuccess = '[CdpMeters] Add Ip Address To Exclusion List Success',
    AddIpAddressToExclusionListError = '[CdpMeters] Add Ip Address To Exclusion List Error',
    RemoveIpAddressExclusionSuccess = '[CdpMeters] Remove Ip Address Exclusion Success',
    RemoveIpAddressExclusionError = '[CdpMeters] Remove Ip Address Exclusion Error',
    RemoveTargetedAudienceSuccess = '[CdpMeters] Remove Targeted Audience Success',
    RemoveTargetedAudienceError = '[CdpMeters] Remove Targeted Audience Error',
}

export enum CustomerDataPlatformMetersHttpActionTypes {
    ClearReusableProperty = '[CdpMeters] Clear Reusable Property',
    ClearMeterState = '[CdpMeters] Clear Data in the Meter Store for a specificied key',

    // OLYTICS SITES ACTIONS
    GetOlyticsSites = '[CdpMeters] Get Olytics Sites',
    GetOlyticSiteMetersList = '[CdpMeters] Get Olytic Site Meters List',
    DeleteDomain = '[CdpMeters] Delete Domain',
    GetOlyticsSiteReport = '[CdpMeters] Get Olytics Site Report',
    GetOlyticsSiteData = '[CdpMeters] Get Olytics Site Data',
    CreateUpdateOlyticsSite = '[CdpMeters] Create Update Olytics Site',

    // METERS ACTIONS
    ShowUnsavedChangesModal = '[CdpMeters] Show Unsaved Changes Modal',
    ExecuteNavbarActionOnStep = '[CdpMeters] Execute Navbar Action On Step',
    GetArchivedMeters = '[CdpMeters] Get Archived Meters',
    SetMeterPriority = '[CdpMeters] Set Meter Priority',
    ArchiveMeter = '[CdpMeters] Archive Meter',
    RestoreArchivedMeter = '[CdpMeters] Restore Archived Meter',
    DeleteMeter = '[CdpMeters] Delete Meter',
    CloneMeter = '[CdpMeters] Clone Meter',
    GetMeterReport = '[CdpMeters] Get Meter Report',
    GetMeterData = '[CdpMeters] Get Meter Data',
    CreateUpdateMeterData = '[CdpMeters] Create Update Meter Data',
    GetMeterMessageContentData = '[CdpMeters] Get Meter Message Content Data',
    GetMeterSingleMessageContent = '[CdpMeters] Get Meter Single Message Content',
    DeleteMeterMessage = '[CdpMeters] Delete Meter Message',
    CloneMeterMessage = '[CdpMeters] Clone Meter Message',
    SaveMeterMessageContent = '[CdpMeters] Save Meter Message Content',
    DeleteOrResetTester = '[CdpMeters] Delete Or Reset Tester',
    AddTester = '[CdpMeters] Add Tester',
    GetMeterHistory = '[CdpMeters] Get Meter History',
    GenerateMeterLink = '[CdpMeters] Genrate Meter Link',
    SetMeterInTestMode = '[CdpMeters] Set Meter In Test Mode',
    SetMeterInActivateMode = '[CdpMeters] Set Meter In Activate Mode',
    GetIpAddressExclusionList = '[CdpMeters] Get Ip Address Exclusion List',
    AddIpAddressToExclusionList = '[CdpMeters] Add Ip Address To Exclusion List',
    RemoveIpAddressExclusion = '[CdpMeters] Remove Ip Address Exclusion',
    RemoveTargetedAudience = '[CdpMeters] Remove Targeter Audience',
}

// ===== HTTP =====
export const ClearReusableProperty = createAction(CustomerDataPlatformMetersHttpActionTypes.ClearReusableProperty);
export const ClearMeterState = createAction(CustomerDataPlatformMetersHttpActionTypes.ClearMeterState, props<{ key: string }>());

// OLYTICS SITES ACTIONS
export const GetOlyticsSites = createAction(CustomerDataPlatformMetersHttpActionTypes.GetOlyticsSites, props<{ payload: MetersSearch }>());
export const GetOlyticSiteMetersList = createAction(CustomerDataPlatformMetersHttpActionTypes.GetOlyticSiteMetersList, props<{ payload: MetersSearch }>());
export const DeleteDomain = createAction(CustomerDataPlatformMetersHttpActionTypes.DeleteDomain, props<{ payload: { environmentId?: number, olyticsSiteId?: number }, reloadData?: MetersSearch }>());
export const GetOlyticsSiteReport = createAction(CustomerDataPlatformMetersHttpActionTypes.GetOlyticsSiteReport, props<{ payload: { environmentId?: number, olyticsSiteId?: number } }>());
export const GetOlyticsSiteData = createAction(CustomerDataPlatformMetersHttpActionTypes.GetOlyticsSiteData, props<{ payload: { environmentId?: number, olyticsSiteId?: number } }>());
export const CreateUpdateOlyticsSite = createAction(CustomerDataPlatformMetersHttpActionTypes.CreateUpdateOlyticsSite, props<{ payload: { environmentId?: number, olyticsSiteId?: number, data: OlyticSiteData } }>());

// METERS ACTIONS
export const ShowUnsavedChangesModal = createAction(CustomerDataPlatformMetersHttpActionTypes.ShowUnsavedChangesModal, props<{ payload: { showModal: boolean } }>());
export const ExecuteNavbarActionOnStep = createAction(CustomerDataPlatformMetersHttpActionTypes.ExecuteNavbarActionOnStep, props<{ payload: NavBarAction }>());
export const GetArchivedMeters = createAction(CustomerDataPlatformMetersHttpActionTypes.GetArchivedMeters, props<{ payload: MetersSearch }>());
export const SetMeterPriority = createAction(CustomerDataPlatformMetersHttpActionTypes.SetMeterPriority, props<{ payload: { contentMeterId: number, direction: string, environmentId?: number, olyticsSiteId?: number } }>());
export const ArchiveMeter = createAction(CustomerDataPlatformMetersHttpActionTypes.ArchiveMeter, props<{ payload: { environmentId?: number, contentMeterId?: number, olyticsSiteId?: number }, reloadData?: MetersSearch }>());
export const RestoreArchivedMeter = createAction(CustomerDataPlatformMetersHttpActionTypes.RestoreArchivedMeter, props<{ payload: { environmentId?: number, contentMeterId?: number, getMeterData?: boolean }, reloadData?: MetersSearch }>());
export const DeleteMeter = createAction(CustomerDataPlatformMetersHttpActionTypes.DeleteMeter, props<{ payload: { environmentId?: number, contentMeterId?: number, olyticsSiteId?: number }, reloadData?: MetersSearch, reloadArchived?: boolean }>());
export const CloneMeter = createAction(CustomerDataPlatformMetersHttpActionTypes.CloneMeter, props<{ payload: { environmentId?: number, contentMeterId?: number, olyticsSiteId?: number }, reloadData?: MetersSearch }>());
export const GetMeterReport = createAction(CustomerDataPlatformMetersHttpActionTypes.GetMeterReport, props<{ payload: { environmentId?: number, contentMeterId?: number } }>());
export const GetMeterData = createAction(CustomerDataPlatformMetersHttpActionTypes.GetMeterData, props<{ payload: { environmentId?: number, contentMeterId?: number } }>());
export const CreateUpdateMeterData = createAction(CustomerDataPlatformMetersHttpActionTypes.CreateUpdateMeterData, props<{ payload: SaveAudiencePayload }>());
export const GetMeterMessageContentData = createAction(CustomerDataPlatformMetersHttpActionTypes.GetMeterMessageContentData, props<{ payload: { contentMeterId: number, offset?: number, numResults?: number, sortBy?: string, order?: string } }>());
export const GetMeterSingleMessageContent = createAction(CustomerDataPlatformMetersHttpActionTypes.GetMeterSingleMessageContent, props<{ payload: { contentMeterId: number, isMeter: boolean, meteringStepId?: number, personalizedMessageId: number, name?: string } }>());
export const DeleteMeterMessage = createAction(CustomerDataPlatformMetersHttpActionTypes.DeleteMeterMessage, props<{ payload: { contentMeterId: number, contentMeterStepId: number, searchData?: SearchData } }>());
export const CloneMeterMessage = createAction(CustomerDataPlatformMetersHttpActionTypes.CloneMeterMessage, props<{ payload: { contentMeterId: number, contentMeterStepId: number } }>());
export const SaveMeterMessageContent = createAction(CustomerDataPlatformMetersHttpActionTypes.SaveMeterMessageContent, props<{ payload: MessageContentSave }>());
export const DeleteOrResetTester = createAction(CustomerDataPlatformMetersHttpActionTypes.DeleteOrResetTester, props<{ payload: { environmentId?: number, contentMeterId: number, contentMeterTesterId: number, mode?: string } }>());
export const AddTester = createAction(CustomerDataPlatformMetersHttpActionTypes.AddTester, props<{ payload: { environmentId?: number, contentMeterId: number, data: { testerEncryptedCustomerId: string, testerAlternateId?: string } } }>());
export const GetMeterHistory = createAction(CustomerDataPlatformMetersHttpActionTypes.GetMeterHistory, props<{ payload: { environmentId?: number, contentMeterId: number, numResults?: number, offset?: number } }>());
export const GenerateMeterLink = createAction(CustomerDataPlatformMetersHttpActionTypes.GenerateMeterLink, props<{ payload: { environmentId?: number, contentMeterId: number, siteUrl: string } }>());
export const SetMeterInTestMode = createAction(CustomerDataPlatformMetersHttpActionTypes.SetMeterInTestMode, props<{ payload: { environmentId?: number, contentMeterId: number, confirmChange?: boolean } }>());
export const SetMeterInActivateMode = createAction(CustomerDataPlatformMetersHttpActionTypes.SetMeterInActivateMode, props<{ payload: { environmentId?: number, contentMeterId: number, confirmChange?: boolean } }>());
export const GetIpAddressExclusionList = createAction(CustomerDataPlatformMetersHttpActionTypes.GetIpAddressExclusionList, props<{ payload: { environmentId?: number, contentMeterId: number, offset?: number, numResults?: number, sortBy?: string, order?: string } }>());
export const AddIpAddressToExclusionList = createAction(CustomerDataPlatformMetersHttpActionTypes.AddIpAddressToExclusionList, props<{ payload: { environmentId?: number, olyticsSiteId: number, contentMeterId: number, ipAddressExclusionList?: Array<{ value?: string, name?: string }>, isUpload: boolean } }>());
export const RemoveIpAddressExclusion = createAction(CustomerDataPlatformMetersHttpActionTypes.RemoveIpAddressExclusion, props<{ payload: { environmentId?: number, contentMeterId: number, ipAddressRuleId: number } }>());
export const RemoveTargetedAudience = createAction(CustomerDataPlatformMetersHttpActionTypes.RemoveTargetedAudience, props<{ payload: { environmentId?: number, contentMeterId: number, contentMeterAudienceId: number } }>());

// ===== INNER =====
// OLYTICS SITES ACTIONS
export const GetOlyticsSitesSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSitesSuccess, props<{ payload: OlyticsSitesResults }>());
export const GetOlyticsSitesError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSitesError, props<{ error: BasicResponse }>());
export const GetOlyticSiteMetersListSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticSiteMetersListSuccess, props<{ payload: { response: OlyticsSiteMeters, olyticsSiteId: number, userMeterPermissions?: UserMeterPermissions } }>());
export const GetOlyticSiteMetersListError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticSiteMetersListError, props<{ payload: { error: BasicResponse, olyticsSiteId: number } }>());
export const DeleteDomainSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteDomainSuccess, props<{ payload: { response: BasicResponse, action?: string } }>());
export const DeleteDomainError = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteDomainError, props<{ error: BasicResponse }>());
export const GetOlyticsSiteReportSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSiteReportSuccess, props<{ payload: ReportData }>());
export const GetOlyticsSiteReportError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSiteReportError, props<{ error: BasicResponse }>());
export const GetOlyticsSiteDataSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSiteDataSuccess, props<{ payload: OlyticSiteData }>());
export const GetOlyticsSiteDataError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetOlyticsSiteDataError, props<{ error: BasicResponse }>());
export const CreateUpdateOlyticsSiteSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.CreateUpdateOlyticsSiteSuccess, props<{ payload: BasicResponse }>());
export const CreateUpdateOlyticsSiteError = createAction(CustomerDataPlatformMetersInnerActionTypes.CreateUpdateOlyticsSiteError, props<{ error: BasicResponse }>());

// METERS ACTIONS
export const GetArchivedMetersSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetArchivedMetersSuccess, props<{ payload: { response: OlyticsSiteMeters, userMeterPermissions?: UserMeterPermissions } }>());
export const GetArchivedMetersError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetArchivedMetersError, props<{ error: BasicResponse }>());
export const SetMeterPrioritySuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterPrioritySuccess, props<{ payload: { response: BasicResponse, action?: string, olyticsSiteId?: number } }>());
export const SetMeterPriorityError = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterPriorityError, props<{ payload: { error: BasicResponse, olyticsSiteId: number } }>());
export const ArchiveMeterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.ArchiveMeterSuccess, props<{ payload: { response: BasicResponse, action?: string, olyticsSiteId?: number } }>());
export const ArchiveMeterError = createAction(CustomerDataPlatformMetersInnerActionTypes.ArchiveMeterError, props<{ payload: { error: BasicResponse, olyticsSiteId: number } }>());
export const RestoreArchivedMeterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.RestoreArchivedMeterSuccess, props<{ payload: BasicResponse }>());
export const RestoreArchivedMeterError = createAction(CustomerDataPlatformMetersInnerActionTypes.RestoreArchivedMeterError, props<{ error: BasicResponse }>());
export const DeleteMeterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteMeterSuccess, props<{ payload: { response: BasicResponse, action?: string, olyticsSiteId?: number } }>());
export const DeleteMeterError = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteMeterError, props<{ payload: { error: BasicResponse, olyticsSiteId: number } }>());
export const CloneMeterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.CloneMeterSuccess, props<{ payload: { response: BasicResponse, action?: string, olyticsSiteId?: number } }>());
export const CloneMeterError = createAction(CustomerDataPlatformMetersInnerActionTypes.CloneMeterError, props<{ payload: { error: BasicResponse, olyticsSiteId: number } }>());
export const GetMeterReportSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterReportSuccess, props<{ payload: ReportData }>());
export const GetMeterReportError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterReportError, props<{ error: BasicResponse }>());
export const GetMeterDataSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterDataSuccess, props<{ payload: {response: MeterDataResponse, contentMeterId: number} }>());
export const GetMeterDataError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterDataError, props<{ error: BasicResponse }>());
export const CreateUpdateMeterDataSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.CreateUpdateMeterDataSuccess, props<{ payload: CreateUpdateMeterDataResponse }>());
export const CreateUpdateMeterDataError = createAction(CustomerDataPlatformMetersInnerActionTypes.CreateUpdateMeterDataError, props<{ error: BasicResponse }>());
export const GetMeterMessageContentDataSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterMessageContentDataSuccess, props<{ payload: MeterMessagesTableResponse }>());
export const GetMeterMessageContentDataError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterMessageContentDataError, props<{ error: BasicResponse }>());
export const GetMeterSingleMessageContentSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterSingleMessageContentSuccess, props<{ payload: SelectedMeterContent }>());
export const GetMeterSingleMessageContentError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterSingleMessageContentError, props<{ error: BasicResponse }>());
export const DeleteMeterMessageSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteMeterMessageSuccess, props<{ payload: BasicResponse }>());
export const DeleteMeterMessageError = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteMeterMessageError, props<{ error: BasicResponse }>());
export const CloneMeterMessageSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.CloneMeterMessageSuccess, props<{ payload: { response?: AddedMeterMessageItem, action?: string } }>());
export const CloneMeterMessageError = createAction(CustomerDataPlatformMetersInnerActionTypes.CloneMeterMessageError, props<{ error: BasicResponse }>());
export const SaveMeterMessageContentSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.SaveMeterMessageContentSuccess, props<{ payload: SaveMeterMessageResponse }>());
export const SaveMeterMessageContentError = createAction(CustomerDataPlatformMetersInnerActionTypes.SaveMeterMessageContentError, props<{ error: BasicResponse }>());
export const DeleteOrResetTesterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteOrResetTesterSuccess, props<{ payload: BasicResponse }>());
export const DeleteOrResetTesterError = createAction(CustomerDataPlatformMetersInnerActionTypes.DeleteOrResetTesterError, props<{ error: BasicResponse }>());
export const AddTesterSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.AddTesterSuccess, props<{ payload: AddTesterResponse }>());
export const AddTesterError = createAction(CustomerDataPlatformMetersInnerActionTypes.AddTesterError, props<{ error: BasicResponse }>());
export const GetMeterHistorySuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterHistorySuccess, props<{ payload: MeterDataResponse }>());
export const GetMeterHistoryError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetMeterHistoryError, props<{ error: BasicResponse }>());
export const GenerateMeterLinkSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GenerateMeterLinkSuccess, props<{ payload: MeterLink }>());
export const GenerateMeterLinkError = createAction(CustomerDataPlatformMetersInnerActionTypes.GenerateMeterLinkError, props<{ error: BasicResponse }>());
export const SetMeterInTestModeSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterInTestModeSuccess, props<{ payload: BasicResponse }>());
export const SetMeterInTestModeError = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterInTestModeError, props<{ error: BasicResponse }>());
export const SetMeterInActivateModeSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterInActivateModeSuccess, props<{ payload: BasicResponse }>());
export const SetMeterInActivateModeError = createAction(CustomerDataPlatformMetersInnerActionTypes.SetMeterInActivateModeError, props<{ error: BasicResponse }>());
export const GetIpAddressExclusionListSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.GetIpAddressExclusionListSuccess, props<{ payload: IpAddressExlusionList }>());
export const GetIpAddressExclusionListError = createAction(CustomerDataPlatformMetersInnerActionTypes.GetIpAddressExclusionListError, props<{ error: BasicResponse }>());
export const AddIpAddressToExclusionListSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.AddIpAddressToExclusionListSuccess, props<{ payload: { contentMeterId?: number, duplicateIPList?: Array<string>, doNotNavigateToNextStep?: boolean } }>());
export const AddIpAddressToExclusionListError = createAction(CustomerDataPlatformMetersInnerActionTypes.AddIpAddressToExclusionListError, props<{ error: BasicResponse }>());
export const RemoveIpAddressExclusionSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.RemoveIpAddressExclusionSuccess, props<{ payload: { doNotNavigateToNextStep?: boolean } }>());
export const RemoveIpAddressExclusionError = createAction(CustomerDataPlatformMetersInnerActionTypes.RemoveIpAddressExclusionError, props<{ error: BasicResponse }>());
export const RemoveTargetedAudienceSuccess = createAction(CustomerDataPlatformMetersInnerActionTypes.RemoveTargetedAudienceSuccess, props<{ payload: BasicResponse }>());
export const RemoveTargetedAudienceError = createAction(CustomerDataPlatformMetersInnerActionTypes.RemoveTargetedAudienceError, props<{ error: BasicResponse }>());
