<ui5-dialog #dialog>
    <div slot="header">
        <div class="w-100 text-end">
            <ui5-button design="Ghost" small type="Secondary" icon-only icon="fa-xmark-solid"
                (click)="close()"></ui5-button>
        </div>
    </div>
    <ng-container>
        <div class="py-5" [innerHTML]="messageContent"></div>
    </ng-container>
    <div slot="footer" class="w-100 ">
        <div class="row d-flex justify-content-end">
            <div class="col-auto">
                <ui5-button type="Secondary" medium design="Ghost" (click)="close()">Close</ui5-button>
            </div>
        </div>
    </div>
</ui5-dialog>